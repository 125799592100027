/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import plaidErrorRequiresUserRelink from "../utils";
import InstitutionHeader from "../InstitutionHeader";
import ConnectionBroken from "../ConnectionBroken";
import AccountsConnected from "../AccountsConnected";
import PlaidLinkButton from "../../PlaidLinkButton";
import styles from "./styles.module.css";

const getPlaidItemStatusAndCallToAction = (plaidItem) => {
    const { is_admin_linked, plaid_error, accounts, internal_plaid_item_id } =
        plaidItem;

    // Plaid item is fine so there's nothing to do.
    if (!plaid_error || !plaid_error.error_code) {
        return {
            plaidItemStatus: (
                <AccountsConnected numberOfAccounts={accounts.length} />
            ),
            callToAction: null,
        };
    }

    if (is_admin_linked) {
        return {
            plaidItemStatus: (
                <AccountsConnected numberOfAccounts={accounts.length} />
            ),
            callToAction: null,
        };
    }

    // To manually force this to break, check if there is a PlaidError associated witht the PlaidItem
    // If there is, set the PlaidError properties to values that requires user relink. If not, create a new PlaidError that requires
    // user relink. Note that the template linked below contains only the fields that need to be set to trigger a user relink on an existing PlaidError.
    // If a PlaidError does not exist yet, you will need to create a new one with all of the fields set, including those specified in the template.
    // https://docs.google.com/document/d/1w3PF3BjnskhcpaIA8Srog5w_-G1UgK7O8AAZat3CEzA/edit?usp=sharing
    // Eventually TODO: use plaid webhook to see if update is required (NEW_ACCOUNT_AVAILABLE)
    // https://linear.app/flychain/issue/ENG-63/consider-using-plaid-webhook-to-gate-update-plaid-item-button
    if (plaidErrorRequiresUserRelink(plaid_error)) {
        return {
            plaidItemStatus: <ConnectionBroken />,
            callToAction: (
                <PlaidLinkButton
                    plaidItemId={internal_plaid_item_id}
                    color="secondary"
                />
            ),
        };
    }

    return {
        plaidItemStatus: (
            <AccountsConnected numberOfAccounts={accounts.length} />
        ),
        callToAction: null,
    };
};

function ProviderPlaidItemHeader({ plaidItem }) {
    const { plaidItemStatus, callToAction } =
        getPlaidItemStatusAndCallToAction(plaidItem);

    return (
        <div className={styles.container}>
            <InstitutionHeader
                plaidItem={plaidItem}
                plaidItemStatus={plaidItemStatus}
            />
            <div className={styles.callToActionContainer}>{callToAction}</div>
        </div>
    );
}

ProviderPlaidItemHeader.propTypes = {
    plaidItem: PropTypes.object.isRequired,
};

export default ProviderPlaidItemHeader;
