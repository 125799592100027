import React from "react";

import PlaidItemsTable from "./PlaidItemsTable";
import { useGetExpiredPlaidItemsQuery } from "../../../redux/slices/adminApi";

function ExpiredPlaidItems() {
    const { data: expiredPlaidItems } = useGetExpiredPlaidItemsQuery();

    return expiredPlaidItems ? (
        <div>
            <p>
                {" "}
                Note to admin: Plaid Errors only reset after Plaid Balance is
                Processed - 9AM ET. To avoid any issues, best to do this check
                right after the Balance script is run. In the future, we should
                update the Plaid Error after succesful plaid link update via
                webhook.
            </p>
            <PlaidItemsTable plaidItems={expiredPlaidItems} />
        </div>
    ) : null;
}

export default ExpiredPlaidItems;
