import React from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import InformationSvg from "../../../assets/Information.svg";
import RightCaretSvg from "../../../assets/RightCaret.svg";
import { getCurrencyString } from "../../../utils";
import COLORS from "../../../styles/colors";

const POSITION_TO_BORDER_RADIUS = {
    single: "8px",
    top: "8px 8px 0 0",
    middle: "0",
    bottom: "0 0 8px 8px",
};

function Line({
    name,
    totalAmountCents,
    ledgerId,
    isInflow,
    numberOfIndents,
    position,
    percentage,
    percentageTooltip,
}) {
    const navigate = useNavigate();
    const isClickable = !!ledgerId;
    const marginLeft = `${34 * numberOfIndents}px`;

    const handleClick = () => {
        if (isClickable)
            navigate(
                `ledgers/${ledgerId}${
                    isInflow !== undefined ? `?isInflow=${isInflow}` : ""
                }`
            );
    };

    const borderStyle = {
        borderRadius: POSITION_TO_BORDER_RADIUS[position],
        borderBottom:
            position === "top" || position === "middle" ? "none" : undefined,
    };

    const nameStyle = {
        marginLeft,
        color: isClickable ? COLORS.BLUE_PURPLE : "#000",
    };

    return (
        <div
            className={`flex flex-row justify-between items-center px-[20px] py-[10px] border border-[#f2f2f2] font-semibold text-[15px] ${
                isClickable && "cursor-pointer hover:bg-[#f0f0f0]"
            }`}
            style={borderStyle}
            onClick={handleClick}
        >
            <div className="w-4/5" style={nameStyle}>
                {name}
            </div>
            <div className="flex flex-row items-center w-1/5">
                {percentage !== undefined && (
                    <>
                        <div className="mr-[5px]">{`${percentage.toFixed(
                            2
                        )}%`}</div>
                        <Tooltip title={percentageTooltip}>
                            <img
                                className="w-[15px]"
                                src={InformationSvg}
                                alt="Tooltip"
                            />
                        </Tooltip>
                    </>
                )}
            </div>
            <div className="flex flex-row justify-end items-center gap-x-[8px]">
                {totalAmountCents !== undefined &&
                    getCurrencyString(totalAmountCents)}
                {isClickable ? (
                    <img
                        className="w-[6px]"
                        src={RightCaretSvg}
                        alt="RightCaret"
                    />
                ) : (
                    <div className="w-[6px]" />
                )}
            </div>
        </div>
    );
}

Line.propTypes = {
    name: PropTypes.string.isRequired,
    totalAmountCents: PropTypes.number,
    ledgerId: PropTypes.string,
    isInflow: PropTypes.bool,
    numberOfIndents: PropTypes.number.isRequired,
    position: PropTypes.oneOf(["single", "top", "middle", "bottom"]).isRequired,
    percentage: PropTypes.number,
    percentageTooltip: PropTypes.node,
};

Line.defaultProps = {
    totalAmountCents: undefined,
    ledgerId: undefined,
    isInflow: undefined,
    percentage: undefined,
    percentageTooltip: undefined,
};

export default Line;
