import React from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../PageTitle";
import useGetMerchantDetailQuery from "../../../../hooks/queriesAndMutations/useGetMerchantDetailQuery";
import MonthlyGraph from "../../../Graphs/MonthlyGraph";
import Summary from "./Summary";
import BackIcon from "../../../BackIcon";
import Table from "./Table";
import SectionTitle from "../../../SectionTitle";

function MerchantDetailView() {
    const { merchantId } = useParams();

    const { data: merchantDetail } = useGetMerchantDetailQuery(merchantId);

    return merchantDetail ? (
        <div className="flex flex-col p-[60px] relative">
            <BackIcon />
            <div className="flex flex-col gap-y-[40px]">
                <div className="flex flex-col gap-y-[30px]">
                    <PageTitle>{merchantDetail.expense_name}</PageTitle>
                    <Summary merchantDetail={merchantDetail} />
                </div>
                <div className="flex flex-col gap-y-[30px]">
                    <SectionTitle>Total Spend Per Month</SectionTitle>
                    <MonthlyGraph data={merchantDetail.monthly_totals} />
                </div>
                <div className="w-4/5">
                    <Table transactions={merchantDetail.transactions} />
                </div>
            </div>
        </div>
    ) : null;
}

export default MerchantDetailView;
