import React from "react";
import PropTypes from "prop-types";

function Card({ title, body }) {
    return (
        <div className="flex flex-col p-6 border-2 border-[#d0c9be] rounded-xl flex-1 w-1/3">
            <div className="mb-4 text-xl font-normal text-center">{title}</div>
            <div className="text-sm font-normal text-center">{body}</div>
        </div>
    );
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
};

export default Card;
