import React from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
    selectDateRange,
    serializeDateRange,
    updateDateRange,
} from "../../../redux/slices/dateRange";
import useGetRevenueByCategoryQuery from "../../../hooks/queriesAndMutations/useGetRevenueByCategoryQuery";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import LedgerTable from "../LedgerTable";
import CustomDateRangePicker from "../../CustomDateRangePicker";
import useIsAdminDashboard from "../../../hooks/useIsAdminDashboard";
import BusinessEntitySelect from "../BusinessEntitySelect";
import StackedBarGraph from "./StackedBarGraph";
import SectionTitle from "../../SectionTitle";
import PageTitle from "../../PageTitle";

const HEADERS = ["Category", "Percentage of Revenue", "Total Revenue", ""];

function Revenue() {
    const { data: revenueData } = useGetRevenueByCategoryQuery();

    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(serializeDateRange(newDateRange)));
    };

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();
    const isAdminDashboard = useIsAdminDashboard();
    const maxDate = isAdminDashboard
        ? dayjs()
        : dayjs(validUpUntilData.valid_up_until);

    return revenueData ? (
        <div className="flex flex-col p-[60px]">
            <BusinessEntitySelect />
            <div className="flex flex-col gap-y-[30px]">
                <PageTitle>Revenue By Category</PageTitle>
                <div className="flex flex-col gap-y-[20px]">
                    <SectionTitle>By Month</SectionTitle>
                    <StackedBarGraph />
                </div>
                <div className="flex flex-col gap-y-[20px]">
                    <SectionTitle>For Date Range</SectionTitle>
                    <CustomDateRangePicker
                        dateRange={dateRange}
                        onChange={setDateRange}
                        maxDate={maxDate}
                    />
                    <LedgerTable data={revenueData} headers={HEADERS} />
                </div>
            </div>
        </div>
    ) : null;
}

export default Revenue;
