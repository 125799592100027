import React from "react";
import PageTitle from "../../PageTitle";
import ValuationRange from "./ValuationRange";
import FreeValuation from "./FreeValuation";
import useGetValuationQuery from "../../../hooks/queriesAndMutations/useGetValuationQuery";

function ValuationEstimate() {
    const { data: valuationData } = useGetValuationQuery();
    return valuationData ? (
        <div className="flex flex-col justify-start p-[60px]">
            <div className="mb-5">
                <PageTitle>Valuation Range</PageTitle>
            </div>
            <div className="font-light text-[15px] mb-5">
                This is our best estimate of the Valuation Range of your
                practice based on your financial data.
            </div>
            <div className="flex flex-row gap-[30px]">
                <div className="flex-1">
                    <ValuationRange />
                </div>
                <div className="flex-1">
                    <FreeValuation />
                </div>
            </div>
        </div>
    ) : null;
}

export default ValuationEstimate;
