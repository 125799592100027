import React, { useState } from "react";
import Alert from "../../../../Alert";
import Modal from "./Modal";

function RecategorizeTransactions() {
    const [openModal, setModalOpen] = useState(false);

    const onClick = () => {
        setModalOpen(true);
    };

    const onClose = () => {
        setModalOpen(false);
    };

    return (
        <div>
            <Alert
                severity="info"
                variant="outlined"
                body={
                    <div>
                        If you&apos;d like to recategorize any transactions,
                        please follow the instructions{" "}
                        <span
                            onClick={onClick}
                            className="cursor-pointer text-flychainBlue"
                        >
                            here
                        </span>
                        .
                    </div>
                }
            />
            {openModal && <Modal onClose={onClose} />}
        </div>
    );
}

export default RecategorizeTransactions;
