import React from "react";
import PropTypes from "prop-types";

function PageTitle({ children, className }) {
    return (
        <div className={`text-3xl font-semibold ${className}`}>{children}</div>
    );
}

PageTitle.propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
};

PageTitle.defaultProps = {
    className: "",
};

export default PageTitle;
