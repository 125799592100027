import firebase from "firebase/compat/app";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });

export async function blobResponseHandler(response) {
    const blob = await response.blob();
    // blob is not serializable so need to convert to base64 before storing in redux.
    const base64 = await blobToBase64(blob);
    return base64;
}

export const prepareHeaders = (idToken) => (headers) => {
    headers.set("authorization", `Bearer ${idToken}`);
    return headers;
};

export const createBaseQuery = (isAdmin) => {
    const baseUrl = isAdmin
        ? `${process.env.REACT_APP_API_URL}/admin`
        : `${process.env.REACT_APP_API_URL}/api`;

    return async (args, api, extraOptions) => {
        const idToken = await firebase.auth().currentUser.getIdToken();

        const fetchQuery = fetchBaseQuery({
            baseUrl,
            prepareHeaders: prepareHeaders(idToken),
        });

        return fetchQuery(args, api, extraOptions);
    };
};
