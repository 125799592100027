import React from "react";
import PropTypes from "prop-types";
import Table from "../../../Table";
import useGetBusinessEntitiesQuery from "../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import { getCurrencyString } from "../../../../utils";

function EBITDATable({ rawResults }) {
    const { data: businessEntities } = useGetBusinessEntitiesQuery();

    const businessEntityIdToEbitdaMap = rawResults.reduce((acc, item) => {
        acc[item.business_entity_id] = item.ebitda_cents;
        return acc;
    }, {});

    const headers = ["Business Entity", "EBITDA"];
    const rows = businessEntities.map((businessEntity) => [
        businessEntity.display_name || businessEntity.legal_name,
        getCurrencyString(businessEntityIdToEbitdaMap[businessEntity.id]),
    ]);

    return <Table headers={headers} rows={rows} />;
}

EBITDATable.propTypes = {
    rawResults: PropTypes.arrayOf(
        PropTypes.shape({
            business_entity_id: PropTypes.string.isRequired,
            ebitda_cents: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
};

export default EBITDATable;
