import React from "react";
import PropTypes from "prop-types";

function SectionTitle({ children }) {
    return <div className="text-xl font-semibold">{children}</div>;
}

SectionTitle.propTypes = {
    children: PropTypes.string.isRequired,
};

export default SectionTitle;
