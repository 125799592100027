import React from "react";
import SectionTitle from "../../SectionTitle";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import NetCashFlowGraph from "./NetCashFlowGraph";
import Report from "./Report";

function CashFlow() {
    return (
        <div className="flex flex-col justify-start p-[60px]">
            <BusinessEntitySelect />
            <div className="mb-[30px]">
                <ValidUpUntil />
            </div>
            <div className="mb-[20px]">
                <SectionTitle>Net Cash Flow by Month</SectionTitle>
            </div>
            <div className="mb-[50px]">
                <NetCashFlowGraph />
            </div>
            <Report />
        </div>
    );
}

export default CashFlow;
