import React from "react";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import Report from "./Report";

function BalanceSheet() {
    return (
        <div className="p-[60px]">
            <BusinessEntitySelect />
            <div className="mb-[30px]">
                <ValidUpUntil />
            </div>
            <Report />
        </div>
    );
}

export default BalanceSheet;
