import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

function DayButton({ date, value, onClick, maxDate }) {
    const newDate = date.date(value + 1);

    const disabled = newDate.isAfter(maxDate, "day");
    const handleClick = () => {
        if (!disabled) {
            onClick(newDate);
        }
    };

    const className = classNames(
        "justify-center items-center px-2.5 py-2 w-9 text-sm font-medium text-center align-middle bg-white rounded-xl shadow-md",
        disabled
            ? "text-gray-400 bg-gray-100 cursor-not-allowed"
            : "cursor-pointer hover:bg-gray-50"
    );

    return (
        <div className={className} onClick={handleClick}>
            {value + 1}
        </div>
    );
}

DayButton.propTypes = {
    date: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default DayButton;
