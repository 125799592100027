import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import SectionTitle from "../../../SectionTitle";
import Button from "../../../Button";
import {
    useGetProvidersQuery,
    useGetActiveBookkeeperSubscriptionsQuery,
} from "../../../../redux/slices/adminApi";
import styles from "./styles.module.css";

function PlaidItemsTable({ plaidItems }) {
    const { data: providerIdToProvider } = useGetProvidersQuery();
    const { data: activeBookkeeperSubscriptions } =
        useGetActiveBookkeeperSubscriptionsQuery();
    // eventually, fetching all subscriptions to do this check may become expensive so might need to refactor to just check a subscription for the specific set of providers
    const onClickUpdate = () => {
        // TODO: update URL to be dynamic. Use Partner model and partner_id to get the subdomain
        const updateUrl = `https://application.flychain.us/#/connections`;
        navigator.clipboard.writeText(updateUrl);
    };

    return providerIdToProvider && activeBookkeeperSubscriptions ? (
        <div className={styles.container}>
            <div className={styles.tableContainer}>
                <div className={styles.tableTitleContainer}>
                    <SectionTitle>Expired Plaid Items</SectionTitle>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <div>Internal Plaid Item ID</div>
                            </th>
                            <th>
                                <div>Provider Name</div>
                            </th>
                            <th>
                                <div>Provider ID</div>
                            </th>
                            <th>
                                <div>Error</div>
                            </th>
                            <th>
                                <div>Full Service Bookkeeping?</div>
                            </th>
                            <th>
                                <div>Email</div>
                            </th>
                            <th>
                                <div>Update Plaid</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {plaidItems.map((plaidItem) => (
                            <tr key={plaidItem.id}>
                                <td>
                                    <div>{plaidItem.id}</div>
                                </td>
                                <td>
                                    <div>
                                        {
                                            providerIdToProvider[
                                                plaidItem.provider_id
                                            ].legal_name
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div>{plaidItem.provider_id}</div>
                                </td>
                                <td>
                                    <div>
                                        {
                                            plaidItem.plaid_error
                                                ?.raw_plaid_error.error_message
                                        }
                                    </div>
                                </td>
                                <td>
                                    <Checkbox
                                        checked={activeBookkeeperSubscriptions.some(
                                            (subscription) =>
                                                subscription.provider_id ===
                                                plaidItem.provider_id
                                        )}
                                    />
                                </td>
                                <td>
                                    <div>
                                        {
                                            providerIdToProvider[
                                                plaidItem.provider_id
                                            ].contact_email
                                        }
                                    </div>
                                </td>
                                <td>
                                    <Button
                                        onClick={() =>
                                            onClickUpdate(plaidItem.id)
                                        } // TODO: make visual cue that click worked
                                    >
                                        Copy Plaid Update URL
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    ) : null;
}

PlaidItemsTable.propTypes = {
    plaidItems: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default PlaidItemsTable;
