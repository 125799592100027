import React from "react";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import PageTitle from "../../PageTitle";
import BackIcon from "../../BackIcon";
import { selectDateRange } from "../../../redux/slices/dateRange";
import getDateRangeForAllTimeToEndDate from "./utils";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import useGetDataForLedgerLinesQuery from "../../../hooks/queriesAndMutations/useGetDataForLedgerLinesQuery";
import useGetReportType from "../../../hooks/useGetReportType";
import REPORT_TYPES from "../constants";
import MonthlyGraph from "./MonthlyGraph";
import Lines from "./Lines";

function LedgerLines() {
    const { ledgerId } = useParams();
    const [searchParams] = useSearchParams();
    const isInflow = searchParams.get("isInflow") === "true";

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const reportType = useGetReportType();

    const dateRange =
        reportType === REPORT_TYPES.BALANCE_SHEET
            ? getDateRangeForAllTimeToEndDate(validUpUntilData.valid_up_until)
            : useSelector(selectDateRange);

    const { data: ledgerLinesData } = useGetDataForLedgerLinesQuery(
        dateRange,
        ledgerId,
        isInflow
    );

    const reportTypes = [
        REPORT_TYPES.REVENUE_BY_CATEGORY,
        REPORT_TYPES.EXPENSES_BY_CATEGORY,
        REPORT_TYPES.INCOME_STATEMENT,
    ];

    return ledgerLinesData ? (
        <div className="relative flex flex-col p-[60px]">
            <BackIcon />
            <div className="flex flex-col gap-y-[30px]">
                <PageTitle>{ledgerLinesData.ledger_name}</PageTitle>
                {reportTypes.includes(reportType) && <MonthlyGraph />}
                <Lines ledgerLinesData={ledgerLinesData} />
            </div>
        </div>
    ) : null;
}

export default LedgerLines;
