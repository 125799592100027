import { useParams } from "react-router-dom";
import { serializeDateRange } from "../../../redux/slices/dateRange";
import { useGetDataForLedgerLinesQuery as useAdminGetDataForLedgerLinesQuery } from "../../../redux/slices/adminApi";
import { useGetDataForLedgerLinesQuery as useProviderGetDataForLedgerLinesQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";
import useGetReportType from "../../useGetReportType";
import REPORT_TYPES from "../../../components/FinancialReports/constants";

const useGetDataForLedgerLinesQuery = (dateRange, ledgerId, isInflow) => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    const reportType = useGetReportType();

    const isCashFlow = reportType === REPORT_TYPES.CASH_FLOW;

    if (isAdminDashboard) {
        return useAdminGetDataForLedgerLinesQuery({
            providerId,
            businessEntityId,
            ...serializeDateRange(dateRange),
            ledgerId,
            isCashFlow,
            isInflow,
        });
    }

    return useProviderGetDataForLedgerLinesQuery({
        businessEntityId,
        ...serializeDateRange(dateRange),
        ledgerId,
        isCashFlow,
        isInflow,
    });
};

export default useGetDataForLedgerLinesQuery;
