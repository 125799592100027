import React from "react";
import { useNavigate } from "react-router-dom";
import SectionTitle from "../../SectionTitle";
import Button from "../../Button";
import NetProfitGraph from "../IncomeStatement/NetProfitGraph";
import NetCashFlowGraph from "../CashFlow/NetCashFlowGraph";
import CashBalanceGraph from "../CashFlow/CashBalanceGraph";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";

function FinancialReportsHome() {
    const navigate = useNavigate();

    const onClickForNetProfitGraph = () => {
        navigate("incomeStatement");
    };

    const onClickForNetCashFlowGraph = () => {
        navigate("cashFlow");
    };

    // TODO: one day this should redirect to treasury management
    const onClickForCashBalanceGraph = () => {
        navigate("cashFlow");
    };

    return (
        <div className="flex flex-col p-[60px]">
            <BusinessEntitySelect />
            <div className="flex flex-col gap-y-[30px]">
                <ValidUpUntil />
                <div className="flex flex-col gap-y-[60px]">
                    <div className="flex flex-col gap-y-[20px]">
                        <div className="flex flex-row justify-between">
                            <SectionTitle>Net Profit by Month</SectionTitle>
                            <Button
                                onClick={onClickForNetProfitGraph}
                                color="secondary"
                            >
                                See Income Statement
                            </Button>
                        </div>
                        <NetProfitGraph />
                    </div>
                    <div className="flex flex-col gap-y-[20px]">
                        <div className="flex flex-row justify-between">
                            <SectionTitle>Net Cash Flow by Month</SectionTitle>
                            <Button
                                onClick={onClickForNetCashFlowGraph}
                                color="secondary"
                            >
                                See Cash Flow Report
                            </Button>
                        </div>
                        <NetCashFlowGraph />
                    </div>
                    <div className="flex flex-col gap-y-[20px]">
                        <div className="flex flex-row justify-between">
                            <SectionTitle>Daily Cash Balance</SectionTitle>
                            <Button
                                onClick={onClickForCashBalanceGraph}
                                color="secondary"
                            >
                                See Cash Flow Report
                            </Button>
                        </div>
                        <CashBalanceGraph />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FinancialReportsHome;
