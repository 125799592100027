import React from "react";
import PropTypes from "prop-types";
import CloseSvg from "../../assets/Close.svg";

function Modal({ onClose, children, cardClassName }) {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex flex-row bg-black/50 z-[9998]">
            <div className="flex flex-row justify-center items-center p-[20px] w-full">
                <div
                    className={`flex flex-col bg-white rounded-lg p-[25px] ${cardClassName}`}
                >
                    <div className="flex flex-row justify-end">
                        <img
                            className="cursor-pointer h-[15px]"
                            src={CloseSvg}
                            alt="Close"
                            onClick={onClose}
                        />
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    cardClassName: PropTypes.string,
};

Modal.defaultProps = {
    cardClassName: "",
};

export default Modal;
