import React from "react";
import SectionTitle from "../../SectionTitle";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import NetProfitGraph from "./NetProfitGraph";
import Report from "./Report";

function IncomeStatement() {
    return (
        <div className="p-[60px]">
            <BusinessEntitySelect />
            <div className="mb-[30px]">
                <ValidUpUntil />
            </div>
            <div className="mb-[20px]">
                <SectionTitle>Net Profit by Month</SectionTitle>
            </div>
            <div className="mb-[50px]">
                <NetProfitGraph />
            </div>
            <Report />
        </div>
    );
}

export default IncomeStatement;
