import React, { useState } from "react";
import HeaderSection from "./HeaderSection";
import TaxBenefits from "./TaxBenefits";
import GettingStarted from "./GettingStarted";
import TaxIntroCall from "./TaxIntroCall";
import CalendlyModal from "../CalendlyModal";
import { CALENDLY_URLS } from "../CalendlyModal/constants";
import styles from "./styles.module.css";

function Taxes() {
    const [showTaxCallModal, setShowTaxCallModal] = useState(false);

    const onClick = () => {
        setShowTaxCallModal(true);
    };

    const onClose = () => {
        setShowTaxCallModal(false);
    };

    return (
        <div className={styles.container}>
            <HeaderSection onClick={onClick} />
            <TaxBenefits />
            <GettingStarted onClick={onClick} />
            <TaxIntroCall onClick={onClick} />
            {showTaxCallModal && (
                <CalendlyModal
                    onClose={onClose}
                    calendlyUrl={CALENDLY_URLS.TAX_CALL}
                />
            )}
        </div>
    );
}

export default Taxes;
