import React from "react";
import useGetMonthlyLedgerTotalsQuery from "../../../../hooks/queriesAndMutations/useGetMonthlyLedgerTotalsQuery";
import SectionTitle from "../../../SectionTitle";
import SharedMonthlyGraph from "../../../Graphs/MonthlyGraph";

function MonthlyGraph() {
    const { data } = useGetMonthlyLedgerTotalsQuery();

    return data ? (
        <div className="flex flex-col gap-y-[20px]">
            <SectionTitle>Monthly Totals</SectionTitle>

            <SharedMonthlyGraph data={data} />
        </div>
    ) : null;
}

export default MonthlyGraph;
