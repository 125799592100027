import React from "react";
import PropTypes from "prop-types";
import Line from "../../../Line";

const renderChild = (child, lastChild, isInflow) => {
    const key = child.ledger_id || child.name;

    return (
        <Line
            key={key}
            ledgerId={child.ledger_id || undefined}
            name={child.name}
            totalAmountCents={child.total_amount_cents}
            numberOfIndents={1}
            position={lastChild ? "bottom" : "middle"}
            isInflow={isInflow}
        />
    );
};

function Section({ name, totalAmountCents, isInflow, children }) {
    if (children.length === 0) {
        return (
            <Line
                name={name}
                totalAmountCents={totalAmountCents}
                numberOfIndents={0}
                position="single"
            />
        );
    }

    return (
        <div>
            <Line
                name={name}
                numberOfIndents={0}
                isInflow={isInflow}
                position="top"
            />
            {children.map((child, i) =>
                renderChild(child, i === children.length - 1, isInflow)
            )}
            <Line
                name={`${name} Total`}
                totalAmountCents={totalAmountCents}
                isInflow={isInflow}
                numberOfIndents={0}
                position="bottom"
            />
        </div>
    );
}

Section.propTypes = {
    name: PropTypes.string.isRequired,
    totalAmountCents: PropTypes.number.isRequired,
    isInflow: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

Section.defaultProps = {
    isInflow: undefined,
};

export default Section;
