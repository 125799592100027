import React from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import SectionTitle from "../../../SectionTitle";
import CustomDateRangePicker from "../../../CustomDateRangePicker";
import ExportReportButton from "../../ExportReportButton";
import Summary from "./Summary";
import {
    updateDateRange,
    serializeDateRange,
    selectDateRange,
} from "../../../../redux/slices/dateRange";
import useGetCashFlowReportQuery from "../../../../hooks/queriesAndMutations/useGetCashFlowReportQuery";
import useIsAdminDashboard from "../../../../hooks/useIsAdminDashboard";
import useGetFinancialReportsValidUpUntilQuery from "../../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import Section from "./Section";

function Report() {
    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(serializeDateRange(newDateRange)));
    };

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const isAdminDashboard = useIsAdminDashboard();
    const maxDate = isAdminDashboard
        ? dayjs()
        : dayjs(validUpUntilData.valid_up_until);

    const { data: cashFlowReportData } = useGetCashFlowReportQuery();

    if (!cashFlowReportData) return null;

    const inflows = cashFlowReportData.inflows.record;
    const outflows = cashFlowReportData.outflows.record;

    return (
        <div>
            <div className="mb-[20px]">
                <SectionTitle>Cash Flow Report</SectionTitle>
            </div>
            <div className="flex flex-col gap-y-[30px]">
                <div className="flex flex-row justify-between pr-[20px] items-center">
                    <CustomDateRangePicker
                        dateRange={dateRange}
                        onChange={setDateRange}
                        maxDate={maxDate}
                    />
                    <ExportReportButton />
                </div>
                <Summary data={cashFlowReportData} />
                <div className="flex flex-col gap-y-[20px]">
                    <Section
                        name={inflows.name}
                        totalAmountCents={inflows.total_amount_cents}
                        isInflow
                    >
                        {inflows.children}
                    </Section>
                    <Section
                        name={outflows.name}
                        totalAmountCents={outflows.total_amount_cents}
                        isInflow={false}
                    >
                        {outflows.children}
                    </Section>
                </div>
            </div>
        </div>
    );
}

export default Report;
