import React, { useState } from "react";
import dayjs from "dayjs";
import SectionTitle from "../../../SectionTitle";
import ExportReportButton from "../../ExportReportButton";
import useGetBalanceSheetQuery from "../../../../hooks/queriesAndMutations/useGetBalanceSheetQuery";
import useGetFinancialReportsValidUpUntilQuery from "../../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import DatePicker from "../../../DatePicker";
import Label from "../../../Label";
import Section from "../../Section";

function Report() {
    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const [endDate, setEndDate] = useState(
        dayjs(validUpUntilData.valid_up_until)
    );

    const { data: balanceSheetData } = useGetBalanceSheetQuery(endDate);

    if (!balanceSheetData) return null;

    const asset = balanceSheetData.asset.record;

    const liability = balanceSheetData.liability.record;

    const equity = balanceSheetData.equity.record;

    return (
        <div>
            <div className="mb-[20px]">
                <SectionTitle>Balance Sheet</SectionTitle>
            </div>
            <div className="flex flex-col gap-y-[30px]">
                <div className="flex flex-row justify-between pr-[20px] items-center">
                    <div className="flex flex-col gap-y-[3px]">
                        <Label>End Date</Label>
                        <DatePicker
                            date={endDate}
                            onChange={setEndDate}
                            maxDate={dayjs(validUpUntilData.valid_up_until)}
                        />
                    </div>
                    <ExportReportButton />
                </div>
                <div className="flex flex-col gap-y-[20px]">
                    <Section
                        name={asset.name}
                        totalAmountCents={asset.total_amount_cents}
                    >
                        {asset.children}
                    </Section>
                    <Section
                        name={liability.name}
                        totalAmountCents={liability.total_amount_cents}
                    >
                        {liability.children}
                    </Section>
                    <Section
                        name={equity.name}
                        totalAmountCents={equity.total_amount_cents}
                    >
                        {equity.children}
                    </Section>
                </div>
            </div>
        </div>
    );
}

export default Report;
