const ERROR_CODES = new Set([
    "ITEM_LOGIN_REQUIRED",
    "ITEM_LOGIN_EXPIRED",
    "INVALID_CREDENTIALS",
    "INVALID_MFA",
    "ITEM_NOT_SUPPORTED", // add for Canvas Hearts debugging 11/6 may have to remove one day
    "NO_ACCOUNTS", // add for Innovations debugging 11/6 may have to remove one day
]);

const plaidErrorRequiresUserRelink = (plaidError) =>
    ERROR_CODES.has(plaidError.error_code);

export default plaidErrorRequiresUserRelink;
