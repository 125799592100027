/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import InstitutionHeader from "../InstitutionHeader";
import ConnectionBroken from "../ConnectionBroken";
import AccountsConnected from "../AccountsConnected";
import PlaidLinkButton from "../../PlaidLinkButton";

const getPlaidItemStatusAndCallToAction = (plaidItem) => {
    const { is_admin_linked, internal_plaid_item_id, plaid_error, accounts } =
        plaidItem;

    // Plaid item is fine so there's nothing to do.
    if (!plaid_error || !plaid_error.error_code) {
        return {
            plaidItemStatus: (
                <AccountsConnected numberOfAccounts={accounts.length} />
            ),
            callToAction: null,
        };
    }

    return {
        plaidItemStatus: <ConnectionBroken />,
        callToAction: (
            <div className="flex flex-row flex-1">
                {is_admin_linked && (
                    <PlaidLinkButton
                        plaidItemId={internal_plaid_item_id}
                        color="secondary"
                    />
                )}
                <div className="px-2 text-xs font-normal">
                    {`Plaid item with id = ${internal_plaid_item_id} is broken due to \n\n ${plaid_error.raw_plaid_error.error_message}\n\n Admin should investigate.`}
                </div>
            </div>
        ),
    };
};

function AdminPlaidItemHeader({ plaidItem }) {
    const { is_admin_linked } = plaidItem;

    const { plaidItemStatus, callToAction } =
        getPlaidItemStatusAndCallToAction(plaidItem);

    return (
        <div className="flex flex-row justify-between items-end w-full">
            <div className="mr-2 flex-2 w-fit">
                <InstitutionHeader
                    plaidItem={plaidItem}
                    plaidItemStatus={plaidItemStatus}
                />
            </div>
            {callToAction}
            <div className="font-medium flex-2 text-md w-fit">
                Admin Linked:{" "}
                {is_admin_linked ? (
                    <span className="text-green-600">TRUE</span>
                ) : (
                    <span className="text-red-600">FALSE</span>
                )}
            </div>
        </div>
    );
}

AdminPlaidItemHeader.propTypes = {
    plaidItem: PropTypes.object.isRequired,
};

export default AdminPlaidItemHeader;
