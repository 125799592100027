import React from "react";
import PropTypes from "prop-types";
import LogoSvg from "../../themes/assets/Logo";
import Bottom from "./Bottom";

function SideBar({ children, showLogo }) {
    return (
        <div className="flex flex-col h-full bg-flychainPurple-dark">
            {showLogo && (
                <div className="flex flex-row mt-5 mb-5 ml-5">
                    <img className="w-4/5" src={LogoSvg} alt="Logo" />
                </div>
            )}
            <div className="flex overflow-y-auto flex-col flex-grow scrollbar scrollbar-thumb-flychainPurple-light scrollbar-track-flychainPurple-dark">
                {children}
                <div className="mt-auto">
                    <Bottom />
                </div>
            </div>
        </div>
    );
}

SideBar.propTypes = {
    children: PropTypes.node,
    showLogo: PropTypes.bool,
};

SideBar.defaultProps = {
    children: undefined,
    showLogo: true,
};

export default SideBar;
