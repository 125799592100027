/* eslint-disable camelcase */
import React, { useState } from "react";
import dayjs from "dayjs";
import PageTitle from "../../PageTitle";
import Card from "./Card";
import useGetValuationQuery from "../../../hooks/queriesAndMutations/useGetValuationQuery";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import { getCurrencyString } from "../../../utils";
import NetProfitTable from "./NetProfitTable";
import EBITDATable from "./EBITDATable";
import EBITDAMultiplesModal from "./EBITDAMultiplesModal";
import styles from "./styles.module.css";

function ValuationExplanation() {
    const { data: valuationData } = useGetValuationQuery();

    const { data: businessEntities } = useGetBusinessEntitiesQuery();

    if (!valuationData || !businessEntities) return null;

    const {
        start_date,
        end_date,
        raw_results,
        total_ebitda_cents,
        annualized_ebitda_cents,
        multiple_range,
        valuation_cents,
    } = valuationData;

    const startDate = dayjs(start_date);
    const endDate = dayjs(end_date);
    const timePeriodString = `${startDate.format(
        "MMMM D, YYYY"
    )} to ${endDate.format("MMMM D, YYYY")}`;

    const isNegativeEbitda = annualized_ebitda_cents < 0;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const onClick = () => setIsModalOpen(true);
    const onClose = () => setIsModalOpen(false);

    const ebitdaMultiplesDescription = (
        <div>
            Your EBITDA multiples are based on market standards and can vary
            based on various factors explained{" "}
            <span onClick={onClick} className={styles.openModal}>
                here
            </span>
            . To get more accurate EBITDA multiples, we recommend scheduling a
            free valuation with our M&A partner.
        </div>
    );

    return valuationData ? (
        <div className={styles.container}>
            <div className={styles.titleSection}>
                <div className={styles.pageTitleContainer}>
                    <PageTitle>
                        How Do We Calculate Your Valuation Range?
                    </PageTitle>
                </div>
                <div className={styles.subtitle}>
                    The first step to increasing your valuation is understanding
                    how it&apos;s calculated and what factors go into this
                    calculation.
                </div>
            </div>
            <div className={styles.cardContainer}>
                <Card
                    title="1. Get the Time Period Considered"
                    description="If we have twelve months or more of your financials on our platform, we'll use the trailing twelve months. Otherwise, we'll use as many months as we have."
                >
                    {`We have financials for ${timePeriodString}.`}
                </Card>
            </div>
            <div className={styles.cardContainer}>
                <Card
                    title={`2. Get the Net Profit for ${timePeriodString}`}
                    description="If you have multiple entities, the net profit for each entity is shown above."
                >
                    <NetProfitTable rawResults={raw_results} />
                </Card>
            </div>
            <div className={styles.cardContainer}>
                <Card
                    title={`3. Calculate EBITDA for ${timePeriodString}`}
                    description="EBITDA is calculated by adding back any expenses related to interest, taxes, depreciation, and amortization."
                >
                    <EBITDATable rawResults={raw_results} />
                </Card>
            </div>
            <div className={styles.cardContainer}>
                <Card
                    title="4. Sum Up the EBITDA for All Entities"
                    description="If you have a single entity, you can ignore this step."
                >
                    {getCurrencyString(total_ebitda_cents)}
                </Card>
            </div>
            <div className={styles.cardContainer}>
                <Card
                    title="5. Annualize the EBITDA"
                    description="If the time period considered is less than 12 months, the EBITDA is annualized."
                >
                    {getCurrencyString(annualized_ebitda_cents)}
                </Card>
            </div>
            <div className={styles.cardContainer}>
                <Card
                    title="6. Get the EBITDA Multiples"
                    description={ebitdaMultiplesDescription}
                >
                    {`${multiple_range[0]}x to ${multiple_range[1]}x`}
                </Card>
            </div>
            <div className={styles.cardContainer}>
                {isNegativeEbitda ? (
                    <Card
                        title="7. Calculate the Valuation Range"
                        description="Your annualized EBITDA is negative and therefore we aren't able to provide a Valuation Range at this time. For a full valuation, we recommend scheduling a free valuation with our M&A partner."
                    >
                        No Valuation Range can be provided at this time.
                    </Card>
                ) : (
                    <Card
                        title="7. Calculate the Valuation Range"
                        description="Finally, we apply your multiples to the annualized EBITDA to get your Valuation Range."
                    >
                        {`${getCurrencyString(
                            valuation_cents[0]
                        )} to ${getCurrencyString(valuation_cents[1])}`}
                    </Card>
                )}
            </div>
            {isModalOpen && <EBITDAMultiplesModal onClose={onClose} />}
        </div>
    ) : null;
}

export default ValuationExplanation;
