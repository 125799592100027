import React, { useState } from "react";
import PropTypes from "prop-types";

function HorizontalTabs({ tabs, onChange }) {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const handleTabClick = (index) => {
        setActiveTabIndex(index);
        onChange(index);
    };

    return (
        <div className="flex flex-row">
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={`px-[10px] pr-0 py-[10px] cursor-pointer mr-[10px] font-normal text-base ${
                        activeTabIndex === index &&
                        "font-medium border-b-2 border-flychainGray"
                    }`}
                    onClick={() => handleTabClick(index)}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
}

HorizontalTabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default HorizontalTabs;
