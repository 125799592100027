/* eslint-disable camelcase */
export const transformGetProvidersResponse = (data) => {
    const { providers } = data;

    return providers.reduce((providerIdToProvider, provider) => {
        // eslint-disable-next-line no-param-reassign
        providerIdToProvider[provider.id] = provider;
        return providerIdToProvider;
    }, {});
};

export const transformGetBankBalancesResponse = (data) => {
    const { bank_balances } = data;

    const bankBalances = bank_balances.map((bankBalance) => {
        const createdAt = new Date(bankBalance.created_at);
        return {
            ...bankBalance,
            created_at_epoch: createdAt.valueOf(),
        };
    });

    bankBalances.sort((a, b) => a.created_at_epoch - b.created_at_epoch);

    return bankBalances;
};

export const transformGetTasksResponse = (data) => data.tasks;

export const transformGetTestUsersResponse = (data) => data.test_users;

export const getNetProfitGraphUrl = ({ providerId, businessEntityId }) =>
    `/provider/${providerId}/financial_reports/net_profit_graph?business_entity_id=${businessEntityId}`;

export const getNetCashFlowGraphUrl = ({ providerId, businessEntityId }) =>
    `/provider/${providerId}/financial_reports/cash_flow_graph?business_entity_id=${businessEntityId}`;

export const getCashBalanceGraphUrl = ({ providerId, businessEntityId }) =>
    `/provider/${providerId}/financial_reports/cash_balance_graph?business_entity_id=${businessEntityId}`;

export const getTopExpensesGraphUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/provider/${providerId}/financial_reports/top_expenses?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getFinancialReportsSummaryUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/provider/${providerId}/financial_reports/summary?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getBalanceSheetUrl = ({ providerId, businessEntityId, endDate }) =>
    `/provider/${providerId}/financial_reports/balance_sheet_v2?business_entity_id=${businessEntityId}&end_date=${endDate}`;

export const getCashFlowReportUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/provider/${providerId}/financial_reports/cash_flow_report_v2?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getLedgerLinesUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
    ledgerId,
    isCashFlow,
    isInflow,
}) => {
    const baseUrl = `/provider/${providerId}/financial_reports/ledger_lines?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}&ledger_id=${ledgerId}&is_cash_flow=${isCashFlow}`;
    return isInflow !== undefined
        ? `${baseUrl}&is_inflow=${isInflow}`
        : baseUrl;
};

export const getLastPaymentCheckUrl = (paymentIds) =>
    `/last_payment_check?payment_ids=${paymentIds.join(",")}`;

export const getFinancialReportsValidUpUntilUrl = ({
    providerId,
    businessEntityId,
}) =>
    `/provider/${providerId}/financial_reports/valid_up_until?business_entity_id=${businessEntityId}`;

export const getPlaidLinkTokenUrl = ({ providerId, plaidItemId }) => {
    if (!plaidItemId) {
        return `/provider/${providerId}/plaid/link`;
    }
    return `/provider/${providerId}/plaid/link?plaid_item_id=${plaidItemId}`;
};

export const getDownloadIncomeStatementUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/provider/${providerId}/financial_reports/income_statement/download?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getDownloadIncomeStatementAsyncUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
    email,
}) =>
    `/provider/${providerId}/financial_reports/income_statement/download/async?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}&email=${encodeURIComponent(
        email
    )}`;

export const getDownloadBalanceSheetUrl = ({
    providerId,
    businessEntityId,
    endDate,
}) =>
    `/provider/${providerId}/financial_reports/balance_sheet/download?business_entity_id=${businessEntityId}&end_date=${endDate}`;

export const getDownloadBalanceSheetAsyncUrl = ({
    providerId,
    businessEntityId,
    endDate,
    email,
}) =>
    `/provider/${providerId}/financial_reports/balance_sheet/download/async?business_entity_id=${businessEntityId}&end_date=${endDate}&email=${encodeURIComponent(
        email
    )}`;

export const getDownloadCashFlowReportUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/provider/${providerId}/financial_reports/cash_flow_report/download?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getDownloadCashFlowReportAsyncUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
    email,
}) =>
    `/provider/${providerId}/financial_reports/cash_flow_report/download/async?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}&email=${encodeURIComponent(
        email
    )}`;

export const getDownloadExpensesByCategoryUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/provider/${providerId}/financial_reports/expenses_by_category/download?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getDownloadExpensesByCategoryAsyncUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
    email,
}) =>
    `/provider/${providerId}/financial_reports/expenses_by_category/download/async?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}&email=${encodeURIComponent(
        email
    )}`;

export const getExpensesByMerchantUrl = ({ providerId, businessEntityId }) =>
    `/provider/${providerId}/financial_reports/expenses_by_merchant?business_entity_id=${businessEntityId}`;

export const getRevenueByCategoryUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/provider/${providerId}/financial_reports/revenue_by_category?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getMonthlyRevenueByCategoryUrl = ({
    providerId,
    businessEntityId,
}) =>
    `/provider/${providerId}/financial_reports/revenue_by_category/stacked_graph?business_entity_id=${businessEntityId}`;

export const getMonthlyExpensesByCategoryUrl = ({
    providerId,
    businessEntityId,
}) =>
    `/provider/${providerId}/financial_reports/expenses_by_category/stacked_graph?business_entity_id=${businessEntityId}`;

export const getMonthlyLedgerTotalsUrl = ({
    providerId,
    businessEntityId,
    ledgerId,
}) =>
    `/provider/${providerId}/financial_reports/monthly_ledger_totals?business_entity_id=${businessEntityId}&ledger_id=${ledgerId}`;

export const getEntriesStartUrl = ({ providerId, businessEntityId }) =>
    `/provider/${providerId}/financial_reports/entries_start?business_entity_id=${businessEntityId}`;

export const getIncomeStatementUrl = ({
    providerId,
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/provider/${providerId}/financial_reports/income_statement_v2?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;
