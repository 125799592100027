import React from "react";
import PropTypes from "prop-types";
import SharedModal from "../../../../../Modal";
import PageTitle from "../../../../../PageTitle";
import DownloadSvg from "../../../../../../assets/Download.svg";

function Modal({ onClose }) {
    return (
        <SharedModal onClose={onClose}>
            <div className="flex flex-col gap-y-[40px]">
                <div className="flex flex-col gap-y-[20px]">
                    <PageTitle>How to Recategorize Transactions</PageTitle>
                    <div className="text-base font-normal">
                        We&apos;re currently working on a feature that will
                        allow you to recategorize transactions directly on the
                        platform. In the meantime, please follow the steps
                        below:
                    </div>
                </div>
                <div className="text-base font-light">
                    <ol className="gap-y-[20px]">
                        <li>
                            Download an excel file of the report by clicking on
                            the button that looks like{" "}
                            <img
                                src={DownloadSvg}
                                alt="Download"
                                className="w-[15px] ml-[5px]"
                            />
                        </li>
                        <li>
                            In the excel file, add a comment next to each
                            transaction that you&apos;d like to recategorize
                            e.g. &quot;Move to Supplies Expense&quot;
                        </li>
                        <li>
                            Send an email to{" "}
                            <strong>accounting@flychain.us</strong> with the
                            subject <strong>Recategorize Transactions</strong>{" "}
                            and the excel file attached.
                        </li>
                    </ol>
                </div>
            </div>
        </SharedModal>
    );
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default Modal;
