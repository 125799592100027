/* eslint-disable camelcase */
import React, { useMemo } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Table from "../../../../Table";
import { getCurrencyString } from "../../../../../utils";

const HEADERS = ["Date", "Description", "Amount"];

function LinesTable({ lines }) {
    const rows = useMemo(
        () =>
            lines.map((line) => [
                <div className="whitespace-nowrap">
                    {dayjs(line.datetime).format("MMM D, YYYY")}
                </div>,
                line.name,
                getCurrencyString(line.amount_cents),
            ]),
        [lines]
    );
    return (
        <div className="max-w-[80%]">
            <Table headers={HEADERS} rows={rows} />
        </div>
    );
}

LinesTable.propTypes = {
    lines: PropTypes.arrayOf(
        PropTypes.shape({
            datetime: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            amount_cents: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
};

export default LinesTable;
