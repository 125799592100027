import React from "react";
import { useNavigate } from "react-router-dom";
import LeftCaretSvg from "../../assets/LeftCaret.svg";

function BackIcon() {
    const navigate = useNavigate();
    const onClick = () => {
        navigate(-1);
    };

    return (
        <div
            className="absolute top-[25px] left-[25px] cursor-pointer"
            onClick={onClick}
        >
            <img className="w-[10px]" src={LeftCaretSvg} alt="LeftCaret" />
        </div>
    );
}

export default BackIcon;
