import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import SectionTitle from "../../../SectionTitle";
import Modal from "../../../Modal";
import styles from "./styles.module.css";

function EBITDAMultiplesModal({ onClose }) {
    return (
        <Modal onClose={onClose}>
            <div>
                <div className={styles.sectionTitleContainer}>
                    <SectionTitle>
                        What Factors Affect Your EBITDA Multiples?
                    </SectionTitle>
                </div>
                <div className={styles.subheading}>
                    In healthcare, EBITDA multiples can range from 3x to as high
                    as 15x. The multiples that are ultimately applied when
                    valuing your practice depend on several key factors
                    including:
                </div>
                <div className={styles.row}>
                    <div className={styles.cardContainer}>
                        <Card showTab={false}>
                            <div className={styles.content}>
                                <div className={styles.title}>Specialty</div>
                                <div className={styles.body}>
                                    Different healthcare specialties have
                                    different multiples. Some are more in demand
                                    than others among buyers, which can
                                    significantly affect the valuation multiple.
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className={styles.cardContainer}>
                        <Card showTab={false}>
                            <div className={styles.content}>
                                <div className={styles.title}>Revenue</div>
                                <div className={styles.body}>
                                    The larger your practice, the higher the
                                    multiples. Bigger practices tend to be more
                                    attractive to buyers because they bring in
                                    more consistent revenue streams.
                                </div>
                            </div>
                        </Card>
                    </div>
                    <Card showTab={false}>
                        <div className={styles.content}>
                            <div className={styles.title}>Profitability</div>
                            <div className={styles.body}>
                                Highly profitable practices command higher
                                premiums. Buyers are willing to pay more for
                                businesses that demonstrate strong profitability
                                over time.
                            </div>
                        </div>
                    </Card>
                </div>
                <div className={styles.row}>
                    <div className={styles.cardContainer}>
                        <Card showTab={false}>
                            <div className={styles.content}>
                                <div className={styles.title}>Growth Rate</div>
                                <div className={styles.body}>
                                    Practices with faster growth rates typically
                                    receive a valuation premium. A strong growth
                                    trajectory shows potential buyers that the
                                    business has room to expand.
                                </div>
                            </div>
                        </Card>
                    </div>
                    <Card showTab={false}>
                        <div className={styles.content}>
                            <div className={styles.title}>Other Factors</div>
                            <div className={styles.body}>
                                Other factors, such as payer mix, the tenure of
                                your staff, and patient satisfaction, also play
                                into your valuation.
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </Modal>
    );
}

EBITDAMultiplesModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default EBITDAMultiplesModal;
